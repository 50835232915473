<template>
  <div class="app-auth-sign-in">
    <div class="app-auth-container">
      <div class="auth-header">
        <img
          src="/images/vars_logo.png"
          alt="VARS Technology"
          class="auth-logo"
        />
        <h1>Sign In to Portal</h1>
      </div>
      <p class="auth-description">Please sign in to your account.</p>
      <div class="auth-credentials">
        <div v-show="!ssoChecked" class="form-group">
          <label for="signInEmail" class="form-label">Email Address</label>
          <input
            v-model="email"
            type="email"
            class="form-control"
            id="signInEmail"
            placeholder="example@varsanpr.com"
          />
          <span id="usernameError" class="error-feedback"></span>
        </div>
        <div v-show="ssoChecked" class="form-group">
          <label for="signInPassword" class="form-label">Password</label>
          <input
            v-model="password"
            type="password"
            class="form-control"
            id="signInPassword"
            placeholder="Enter your password"
          />
          <span id="passwordError" class="error-feedback"></span>
        </div>
        <div class="auth-actions">
          <button
            :disabled="checkingSso"
            class="btn btn-primary"
            v-show="!ssoChecked"
            @click="checkForSSO"
          >
            <span
              v-show="checkingSso"
              class="spinner-border spinner-border-sm"
            ></span>
            Continue
          </button>
          <button
            class="btn btn-primary"
            v-show="ssoChecked"
            @click="handleLogin"
          >
            Sign In
          </button>
          <a v-show="ssoChecked" @click="goBack"> Back </a>
          <a href="/forgot" class="auth-link">Forgot Password?</a>
        </div>
        <p v-show="message" class="text-danger">{{ message }}</p>
      </div>
    </div>
    <form
      id="ssoForm"
      style="display: none"
      action="https://api.varsanpr.com/api/auth/signin"
      method="POST"
    >
      <input type="hidden" name="email" :value="email" />
    </form>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import authService from "../../services/auth.service";
import axios from "axios";

export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Username is required!"),
      password: yup.string().required("Password is required!"),
    });

    return {
      loading: false,
      message: "",
      schema,
      redirect_url: this.$route.query.redirect,
      ssoChecked: false,
      email: "",
      password: "",
      checkingSso: false,
    };
  },
  mounted() {
    if (this.redirect_url) {
      let acceptableDomains = [
        "https://portal.varsanpr.com/login",
        "https://admin.varsanpr.com/login",
        "https://nbcs.varsanpr.com/login",
        "https://server.varsanpr.com/login",
        "https://dcbl.varsanpr.com/login",
        "https://claims.varsanpr.com/login",  
        "https://portal.varsanpr.com",
        "https://admin.varsanpr.com",
        "https://nbcs.varsanpr.com",
        "https://server.varsanpr.com",
        "https://dcbl.varsanpr.com",
        "https://claims.varsanpr.com",
        "http://localhost:8000/login",
        "http://localhost:8000",
      ];

      if (!acceptableDomains.includes(this.redirect_url)) {
        this.redirect_url = undefined;
      }
    }

    if (this.$route.query.token) {
      authService
        .checkToken(this.$route.query.token)
        .then(() => {
          if (this.redirect_url) {
            console.log(`Redirecting to ${this.redirect_url}`);
            window.location.href =
              this.redirect_url + "?token=" + this.$route.query.token;
          } else {
            console.log(`No redirect provided. Redirecting to home page.`);
            window.location.href = window.location.origin;
          }
        })
        .catch((error) => {
          this.$store.dispatch("auth/logout");
        });
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    night() {
      if (this.$store.state.auth.status.dark == undefined) return true;
      else return this.$store.state.auth.status.dark;
    },
  },
  created() {
    if (this.loggedIn) {
      authService
        .checkToken(this.$store.state.auth.user.token)
        .then(() => {
          if (this.redirect_url) {
            console.log(`Redirecting to ${this.redirect_url}`);
            window.location.href =
              this.redirect_url + "?token=" + this.$store.state.auth.user.token;
          } else {
            console.log(`No redirect provided. Redirecting to home page.`);
            window.location.href = window.location.origin;
          }
        })
        .catch(() => {
          this.$store.dispatch("auth/logout");
        });
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;

      this.$store
        .dispatch("auth/login", {
          username: this.email,
          password: this.password,
        })
        .then(
          () => {
            if (this.redirect_url == undefined) this.$router.push("/");
            else
              window.location.href =
                this.redirect_url +
                "?token=" +
                this.$store.state.auth.user.token;
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            if (this.message == "Password is not valid") {
              this.message = `Password is not valid. ${error.response.data.remaining} attempts remaining.`;
            } else if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              this.message = error.response.data.message;
            } else {
              this.message =
                "The email address or password entered is incorrect. Please try again.";
            }
          }
        );
    },
    ssoLogin() {
      window.location.href =
        "https://api.varsanpr.com/api/auth/signin" +
        "?redirect=" +
        this.redirect_url;
    },
    checkForSSO() {
      this.checkingSso = true;
      axios
        .post(`https://api.varsanpr.com/api/auth/sso`, {
          email: this.email,
        })
        .then((response) => {
          if (response.data.sso == true) {
            document.getElementById("ssoForm").submit();
          }
          this.ssoChecked = true;
        })
        .catch((error) => {
          this.ssoChecked = true;
        })
        .finally(() => {
          this.checkingSso = false;
        });
    },
    goBack() {
      this.ssoChecked = false;
    },
  },
};
</script>

<style scoped>
.app-auth-sign-in {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
}

.app-auth-container {
  max-width: 600px;
  max-height: max-content;
  margin: 0 auto;
  padding: 50px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.text-danger {
  text-align: center;
}

@media (max-width: 768px) {
  .app-auth-sign-in {
    height: 100vh;
    width: 100vw;
  }

  .app-auth-container {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    padding: 20px;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .auth-header {
    margin-bottom: 10px;
  }

  .auth-description {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .btn {
    margin-bottom: 10px;
  }

  .auth-link {
    margin-top: 5px;
  }
}

.auth-header {
  text-align: center;
  margin-bottom: 20px;
}

.auth-logo {
  height: 50px;
  margin-bottom: 10px;
}

.auth-description {
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.btn {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  margin-bottom: 15px;
}

.auth-link {
  display: block;
  text-align: center;
  margin-top: 10px;
  color: #007bff;
}

.auth-link:hover {
  text-decoration: underline;
}

.auth-background-light {
  background: url("/images/vars_logo_black.png") no-repeat center center;
  background-size: 50%;
}

.auth-background-dark {
  background: url("/images/vars_logo_white.png") no-repeat center center;
  background-size: 50%;
}

.error-feedback {
  color: #ff5e5e;
  font-size: 12px;
  margin-top: 5px;
}
</style>
